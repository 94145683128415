<title>Summary</title>
<div id="summary" class="row justify-content-center">
    <div class="col-sm-12">
        <div class=" callout-container-shaded">
            <div class="row padded-row mt-3">
                <div class="col-sm-12 no-padding">
                    <div class="green-title-lg">
                        Summary & Planning
                    </div>
                    <div id="rptIntro1" class="mb-2 mt-3  intro-label">
                        Based upon the information you entered, your estimated balance at retirement will be
                        <span class="text-value">{{projRetBalance | currency:'USD':'symbol':'1.0-0'}}.</span>
                    </div>
                </div>
            </div>

        
        <div class="row padded-row mt-3">
            <div class="callout-container col-md-12">
                <div class="row">

                    <div class="col-sm-12 green-title">
                        Your estimated health savings account (HSA) savings at retirement
                    </div>
                    <div class="col-sm-12 mt-3">
                        <div class="row no-gutters">
                            <div class="green-shaded left col-sm-1">
                                <img class="icon-mega-horn" src="assets/img/mega_horn.png" alt="icon-saver">
                            </div>
                            <div id="rptIntro2" class="green-shaded-right left col-sm-11">
                                You could need more than <span class="text-value">{{retNeedSingle | currency:'USD':'symbol':'1.0-0'}}</span> for an individual, and <span class="text-value">{{retNeedFamily | currency:'USD':'symbol':'1.0-0'}}</span> for a couple to cover health care expenses in retirement.
                            </div>
                        </div>
                    </div>

                    <!-- Bar Charts and Calculated Values-->
                    <div class="col-sm-12 mt-1">
                        <div class="row no-gutters">
                            <div class="legend-box">
                                <!-- <div class="" (click)="toggleSeries('proj')"> -->
                                    <div class="legend-title">
                                        Estimated savings at retirement 
                                        <!-- <span class="nowrap">age <img triggers="click" placement="auto top" [outsideClick]="true" 
                                            [popover]="savingsRetireTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></span> -->
                                    </div>
                                    <div id="projRetBalance" class="legend-value">
                                        {{projRetBalance | currency:'USD':'symbol':'1.0-0'}}
                                    </div>
                                <!-- </div> -->
                                <!-- <hr class="hr-nomargin">
                                <div class="row-same-height" (click)="toggleSeries('alt')">
                                    <div class="legend-title">
                                        Potential balance with changes to savings/spending <img triggers="click" placement="auto bottom" [outsideClick]="true" [popover]="balanceTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png">
                                    </div>
                                    <div id="altRetBalance" class="legend-value-gray">
                                        {{altRetBalance | currency:'USD':'symbol':'1.0-0'}}
                                    </div>
                                </div> -->
                                <hr class="hr-nomargin">
                                <div class="  row-same-height">
                                    <div class="legend-title">
                                        Cumulative tax savings
                                    </div>
                                    <div id="" class="legend-value">
                                        {{cumulativeTaxSavings | currency:'USD':'symbol':'1.0-0'}}
                                    </div>
                                </div>
                            </div>
                            <div class="row no-gutters  justify-content-end legend-box-btn col mt-1">
                                <a class="btn btn-getstarted btn-block btn-border" 
                                href="https://www.fidelity.com/go/hsa/why-hsa?ccsource=calculator_web_redirect_2023" target="_blank" >
                                    Get started
                                </a>
                            </div>
                        </div>
                        <div  class="col-md-12 col-12 col-same-height">
                            <ng-container>
                                <highcharts-chart [oneToOne]="true" [(update)]="updateFlag" [Highcharts]="Highcharts" style="display: block;"  [options]="options"></highcharts-chart>
                            </ng-container>
            
                        </div>   
                    </div>

                    <!--     -->
                    
            </div>
            <div class="container ">
                <div style="font-size: 16px" class="col-sm-12 mt-5 mb-5 no-padding">
                    <b>Adjust your assumptions below to see how changes may impact your potential balance.</b>
                </div>
            </div>
            <div class="container">
                <div class="row display-flex">
                    <!-- Assumptions and Summary Sections-->

                    <div class="col-md-12 col-12 col-same-height">
                        <div style="" class="">
                            <span class="green-title">
                                <img  height="58px" class="" src="assets/img/icon_assumptions.png" class="infoicon icon-assumptions" alt="icon-assumptions">
                                Your assumptions
                            </span>
                            <div class="summary-panel">

                                <div class="">
                                    <form novalidate [formGroup]="formSummary">
                                        <div class="row vbottom w-100">
                                            <!-- <div style="margin-bottom: -30px;" class="offset-md-3 col-md-9 vbottom">
                                                <div class="input-label">Current tax<br>rate?</div>
                                            </div> -->
                                            <div class="col-sm-4 vbottom">
                                                <div class="form-group">
                                                    <label class="input-label bold" for="retirementAge">Retirement age</label>
                                                    <select class="form-control" aria-label="Retirement age" (ngModelChange)="setRetAge($event);calc()" formControlName="retirementAge">
                                                        <option *ngFor="let retirementAge of retireAgeOptions"  [value]="retirementAge">
                                                            {{retirementAge}}
                                                        </option>
                                                    </select>
                                                    <app-error-message [errorMessage]="formErrors.retirementAge"></app-error-message>
                                                </div>
                                            </div>
                                            <div class="col-sm-4 vbottom">
                                                <div class="form-group ">
                                                    <label class="input-label bold" for="fedTax">Current tax rate<br>Federal 
                                                        <!-- <img triggers="click" placement="top" [outsideClick]="true" [popover]="fedTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"> -->
                                                    </label>
                                                    <select class="form-control " aria-label="Federal Tax" (change)="setFedTax(fedTaxOptions[$event.target.selectedIndex])" formControlName="fedTax">
                                                        <option *ngFor="let fed of fedTaxOptions" [value]="fed">
                                                            {{fed}}%
                                                        </option>
                                                    </select>
                                                    <app-error-message [errorMessage]="formErrors.fedTax"></app-error-message>
                                                </div>
                                            </div>

                                            <div class="col-sm-4 vbottom">
                                                <div class="form-group">
                                                    <label class="input-label bold" for="stateTax">State 
                                                        <!-- <img triggers="click" placement="top left" [outsideClick]="true" [popover]="stateTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"> -->
                                                    </label>
                                                    <select aria-label="State Tax" class="form-control taxdropdown"  
                                                        (change)="setStateTax(stateTaxOptions[$event.target.selectedIndex])" >
                                                        <option *ngFor="let staterates of stateTaxOptions"
                                                            [value]="element"
                                                            [selected]="staterates.rate === stateTax && staterates.state === state" >
                                                            {{staterates.state}} - {{staterates.rate}}%
                                                        </option>
                                                    </select>
                                                    <app-error-message [errorMessage]="formErrors.stateTax"></app-error-message>
                                                </div>
                                                <!-- <div class="form-group">
                                                    <label class="input-label" for="stateTax">State  <img  triggers="click" [tooltip]="stateTaxTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"></label>
                                                    <select class="form-control " (ngModelChange)="calc()" formControlName="stateTax">
                                                        <option *ngFor="let state of stateTaxOptions" [value]="state">
                                                            {{state}}%
                                                        </option>
                                                    </select>
                                                    <app-error-message [errorMessage]="formErrors.stateTax"></app-error-message>
                                                </div> -->
                                            </div>
                                        </div> <!-- end row -->

                                        <div class="row w-100">
                                            <div class="col-sm-12 no-padding mt-3">
                                                    <!-- What is your investment risk tolerance? -->
                                                <div class="input-label bold mb-3">Rate of return</div>
                                                <div id="slider-ror" class="form-group slider-ror">
                                                    <span id="rorlabelSum" class="sr-only">Rate of Return Slider</span>
                                                    <input id="ror" aria-label="Rate of return" formControlName="ror" type="text">
                                                    <div class="row slider-labels">
                                                        
                                                        <div class="col-sm-4 d-none d-sm-block  col-md-4 slider-text">
                                                            <div class="text-left normal">Conservative<br>0-3%</div>
                                                        </div>
                                                        <div class="col-sm-4 d-none d-sm-block slider-text">
                                                            <div class="text-center normal">Moderate<br>4-7%</div>
                                                        </div>
                                                        <div class="col-sm-4 d-none d-sm-block  col-md-4 slider-text">
                                                            <div class="text-right normal">Aggressive<br>8-10%</div>
                                                        </div>

                                                        <div class="col-6 d-block d-sm-none slider-text">
                                                            <div class="text-left normal">Conservative</div>
                                                        </div>
                                                        <div class="col-6 d-block d-sm-none slider-text">
                                                            <div class="text-right normal">Aggressive</div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div class="col-sm-12 mt-4 mb-3">
                                                <div class="input-label bold">
                                                    Slide the bar to see the impact of saving up to <span class="text-value">{{this.hsaMaxContrib | currency:'USD':'symbol':'1.0-0'}}</span> annually from your HSA.  
                                                    <!-- <img triggers="click" placement="auto top" [outsideClick]="true" [popover]="savingSliderTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-12 no-padding">
                                                <div class="row form-group slider-2">
                                                    <div class="col-1 no-padding-left">
                                                        <div class="text-right slider-end-label">{{0 | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <!-- <div class="text-right sliderSubLabel">(annually)</div> -->
                                                        <div class="text-right sliderSubLabel">(annually)</div>
                                                    </div>
                                                    <div class="col-10">
                                                        <span id="hsaContriblabelSum" class="sr-only">HSA Contributions Slider</span>
                                                        <input id="hsaContribAlt" aria-label="HSA Contributions" formControlName="hsaContribAlt" type="text">
                                                    </div> 
                                                    <div class="col-1 no-padding-left">
                                                        <div class="text-right slider-end-label nobr">{{hsaMaxContrib | currency:'USD':'symbol':'1.0-0'}}
                                                            <!-- <img triggers="click" placement="auto top left" [outsideClick]="true" [popover]="spendingSliderTip" 
                                                                alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"> -->
                                                        </div>
                                                        <!-- <div class="text-right sliderSubLabel">(annually)</div> -->
                                                        <div class="text-right sliderSubLabel">(annually)</div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                
                                            <div class="col-sm-12 no-padding mt-4 mb-4">
                                                <div class="input-label bold">
                                                    <!-- {{formData.hsaOOP | currency:'USD':'symbol':'1.0-0'}} -->
                                                    Slide the bar to see the impact of spending less than <span class="text-value">{{hsaMaxSpend | currency:'USD':'symbol':'1.0-0'}}</span> annually from your HSA.  
                                                    <!-- <img triggers="click" placement="auto" [outsideClick]="true" [popover]="spendingSliderTip" alt="icon-help" class="helpIcon" src="assets/img/helpIcon.png"> -->
                                                </div>
                                            </div>
                                            <div class="col-sm-12 no-padding">
                                                <div class="row form-group slider-2">
                                                    <div class="col-1 no-padding-left">
                                                        <div class="text-right slider-end-label">{{hsaMinSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <div class="text-right sliderSubLabel">(annually)</div>
                                                    </div>
                                                    <div class="col-10">
                                                        <span id="hsaSpendlabelSum" class="sr-only">HSA out-of-pocket health care costs Slider</span>
                                                        <input id="hsaSpendAlt" aria-label="HSA out-of-pocket health care costs" formControlName="hsaSpendAlt" type="text">
                                                    </div>
                                                    <div class="col-1 no-padding-left">
                                                        <div class="text-right slider-end-label">{{hsaMaxSpend | currency:'USD':'symbol':'1.0-0'}}</div>
                                                        <div class="text-right sliderSubLabel">(annually)</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- </div>  -->
                                        </div> <!-- end row W-100 -->
                                    </form>
                                </div> <!-- end container-fluid -->       
                            </div> <!-- end col-8 -->
                        </div>
                    </div>
                    <!-- <div class="col-md-4 col-12 col-same-height no-padding right-summary">
                        <div  class="">
                            <span class="green-title">
                                <img  height="58px" class="" src="assets/img/icon_finances.png" class="infoicon icon-finances" alt="icon-finances">
                                Your summary
                            </span>
                            <div class="summary-panel">
                            <div *ngFor="let item of summaryItems no-padding">
                                <app-summary-item [summaryItem]="item"></app-summary-item>
                                
                            </div>
                        </div>
                    </div> -->

                </div> <!-- end row -->
            </div> <!-- end container -->
            <div class="container">
                <div class="row mt-5">
                    <div class="col-sm-12 no-padding">
                        <hr class="hr-thick-fullwidth">
                    </div> 
                </div> 
            </div> 

            <div class="row display-flex">
                <div class="col-sm-12 no-padding">
                    <div class="">
                        <app-navigation></app-navigation>
                    </div> 
                </div> 
            </div>
        </div>
    </div> <!-- end col-sm-10 -->
</div> <!-- end row justify-content-center -->


<ng-template #stateTaxTip>
    <span>
    Any HSA tax advantages referenced in this tool are with respect to federal taxation only. Contributions, investment earnings, and distributions may or may not be subject to state taxation. The state tax rates are intended for guidance purposes only and you should check with your tax advisor for more details.
    </span>
</ng-template>

<ng-template #fedTaxTip>
    <span>
        Your HSA contributions are not subject to federal income tax. Providing your federal tax rate will 
        help us estimate your potential annual tax savings. Need help estimating your tax 
        rate? <a target="_blank" href='https://www.fidelity.com/tax-information/tax-brackets'>https://www.fidelity.com/tax-information/tax-brackets</a>
    </span>
    <br/><br/>
    <span>
        Contributions, investment earnings, and distributions may or may not be subject to state taxation. State tax rates are intended for 
        guidance purposes only and you should check with your tax advisor for more details. 
    </span>
</ng-template>

<ng-template #savingsRetireTip>
    <span>
    This is calculated based on your initial inputs.
    </span>
</ng-template>

<ng-template #balanceTip>
    <span>
    This is calculated based on your changes to your assumptions below.
    </span>
</ng-template>

<ng-template #savingSliderTip>
    <span>
    The HSA Contribution limits for 2023 are $3,850 for individual coverage and $7,750 for family coverage. Those 55 and older can contribute an additional $1,000 as a catch-up contribution.
    </span>
</ng-template>

<ng-template #spendingSliderTip>
    <span>
    Out-of-pocket expenses are any expenses for health care that your insurance doesn't reimburse you for. These can include things like deductibles, coinsurance, or items that aren't covered by your insurance.
    </span>
</ng-template>